<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Business Name"
            label-for="business_name"
          >
            <b-form-input
              id="business_name"
              v-model="filters['business_name']"
              placeholder="Search"
              autocomplete="nope"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          lg="3"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="filters['is_active']"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
              deselect-from-dropdown
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-row>
        <b-col>
          <div
            class="my-2 mx-2"
          >
            <b-row>
              <b-col
                md="4"
                class="d-block d-sm-flex flex-wrap justify-content-start align-items-center mb-1 mb-md-0"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  @click="onClickCreate()"
                >
                  <feather-icon icon="PlusIcon" />
                  Add New
                </b-button>
              </b-col>
              <b-col
                class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
              >
                <JsonExcel
                  class="btn p-0"
                  :data="rows"
                  :fields="json_fields"
                  type="csv"
                  :name="`Clients - Page ${page}.xls`"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="Download"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <JsonExcel
                  class="btn p-0"
                  :fetch="getClientListNoPagination"
                  :before-generate="__showJsonDownloadAlert"
                  :fields="json_fields"
                  type="csv"
                  name="Clients.xls"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - All'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="DownloadMultiple"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary="'Refresh Table'"
                  variant="outline-primary"
                  size="sm"
                  @click="onClickRefresh()"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
              @sort-changed="sortChanged"
            >
              <template #cell(business_settings.company_name)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    :text="avatarText(data.item.business_settings ? data.value : data.item.name)"
                    size="32"
                    :src="`${data.item.business_settings ? data.item.business_settings.company_logo || '' : ''}`"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1"
                    >
                      {{ data.item.business_settings ? data.value : data.item.name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(business_settings.company_address)="data">
                {{ data.item.business_settings ? data.value : '---' }}
              </template>
              <template #cell(business_settings.company_finance_email)="data">
                {{ data.item.business_settings ? data.value : '---' }}
              </template>
              <template #cell(business_settings.company_phone)="data">
                {{ data.item.business_settings ? data.value : '---' }}
              </template>
              <template #cell(subscription)="data">
                {{ data.value || '---' }}
              </template>
              <template #cell(status)="data">
                <b-badge :variant="data.value == 'Active' ?'light-success':data.value == 'Inactive' ? 'light-danger' : 'light-warning'">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickSetStatus(data.item.id)"
                  >
                    <feather-icon icon="EditIcon" />
                    Set Status
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="onClickRunSeeders(data.item.id, data.item.business_settings ? data.item.business_settings.company_name : data.item.name)"
                  >
                    <feather-icon icon="ChevronsUpIcon" />
                    Run Seeders
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75 mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <set-client-modal ref="set_client_modal" />
    <set-client-status-modal ref="set_client_status_modal" />
    <seeder-modal ref="seeder_modal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BDropdown,
  BFormGroup,
  BFormInput,
  BButton,
  BDropdownItem,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import JsonExcel from 'vue-json-excel'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SetClientModal from '@/components/Clients/SetClientModal.vue'
import SetClientStatusModal from '@/components/Clients/SetClientStatusModal.vue'
import SeederModal from '@/components/Seeders/SeederModal.vue'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const clientRepository = RepositoryFactory.get('client')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BAvatar,
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BOverlay,
    BDropdown,
    BFormGroup,
    BBadge,
    BFormInput,
    BButton,
    BDropdownItem,
    vSelect,
    BCardActions,
    SetClientModal,
    SetClientStatusModal,
    SeederModal,
    JsonExcel,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      value: 25,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {},
      statusOptions: [
        {
          key: '1',
          name: 'Active',
        },
        {
          key: '0',
          name: 'Inactive',
        },
      ],
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'business_settings.company_name',
          label: 'Business',
          sortable: true,
        },
        {
          key: 'business_settings.company_address',
          label: 'Business Address',
          sortable: false,
        },
        {
          key: 'business_settings.company_finance_email',
          label: 'Business Email',
          sortable: false,
        },
        {
          key: 'business_settings.company_phone',
          label: 'Business Contact',
          sortable: false,
        },
        {
          key: 'subscription',
          label: 'Subscription',
          sortable: false,
        },
        {
          key: 'business_settings.company_currency',
          label: 'Currency',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
      json_fields: {
        'Company Name': 'business_settings.company_name',
        'Company Address': 'business_settings.company_address',
        'Company Email': 'business_settings.company_finance_email',
        'Company Phone': 'business_settings.company_phone',
        Subscription: 'subscription',
        'Company Currency': 'business_settings.company_currency',
        'Is Active': 'is_active',
      },
    }
  },
  watch: {
    page() {
      this.getClientList()
    },
    perPage() {
      this.getClientList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
  },
  methods: {
    async getClientList() {
      this.loading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await clientRepository.getClientList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getClientListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await clientRepository.getClientListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickViewNotification(notification) {
      this.$refs.view_notification_modal.openModal(notification)
    },
    onClickCreate() {
      this.$refs.set_client_modal.openModal(null, 'add')
    },
    onClickUpdate(id) {
      this.$refs.set_client_modal.openModal(id, 'edit')
    },
    onClickRunSeeders(id, tenantName) {
      this.$refs.seeder_modal.openModal(id, tenantName)
    },
    onClickSetStatus(id) {
      this.$refs.set_client_status_modal.openModal([id])
    },
    // REQUIRED
    filterQueryUpdate() {
      this.getClientList()
    },
    handleChangePage(page) {
      this.page = page
      this.getClientList()
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getClientList()
      }
    },
    onClickRefresh() {
      this.getClientList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
