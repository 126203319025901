<template>
  <div>
    <b-modal
      id="modal-client-set"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      :title="modalTitle"
      centered
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createClientForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="Tenant Name"
                  label-cols-md="4"
                  label-for="h-cName"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    placeholder="Enter Tenant Name"
                    type="text"
                    autocomplete="nope"
                    pattern="[a-z0-9_]+"
                    title="Tenant name can only contain lowercase letters and underscores"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="Business Name"
                  label-cols-md="4"
                  label-for="bName"
                >
                  <b-form-input
                    id="bName"
                    v-model="form.company_name"
                    placeholder="Enter Business Name"
                    type="text"
                    autocomplete="nope"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  class="required"
                  label="Business Email"
                  label-cols-md="4"
                  label-for="bEmail"
                >
                  <b-form-input
                    id="bEmail"
                    v-model="form.company_email"
                    placeholder="Enter Business Email"
                    type="email"
                    autocomplete="nope"
                    required
                  />
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                class="d-flex justify-content-end align-items-center mb-1"
              >
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="clearOnClick"
                  >
                    Clear
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { apiUrl } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ClientRepository = RepositoryFactory.get('client')

function initialState() {
  return {
    apiUrl,
    open: false,
    id: null,
    modalPurpose: 'add',
    loading: false,
    fields: [],
    form: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.modalPurpose === 'add' ? 'ADD NEW CLIENT' : 'UPDATE CLIENT'
    },
  },
  methods: {
    async openModal(id, purpose) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.modalPurpose = purpose
      this.id = id
      if (this.modalPurpose === 'edit') {
        await this.fetchClientDetails(this.id)
      }
    },
    async fetchClientDetails(id) {
      try {
        this.loading = true
        const { data } = (await ClientRepository.getClientById(id)).data
        this.form = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleFormSubmit() {
      try {
        this.loading = true
        let res
        const payload = {
          name: `${this.form.name.replaceAll('_tenant', '')}_tenant`,
          domain: apiUrl.replaceAll('/api', ''),
          database: `${this.form.name.replaceAll('_tenant', '')}_tenant`,
          company_name: this.form.company_name,
          company_email: this.form.company_email,
        }
        if (this.modalPurpose === 'add') {
          res = await ClientRepository.createClient(payload)
        } else {
          res = await ClientRepository.updateClient(this.id, payload)
        }
        if (res.status === 201) {
          this.clearOnClick()
          this.open = false
          this.$parent.onClickRefresh()
          this.showSuccessMessage(`Client ${this.modalPurpose === 'add' ? 'Added' : 'Updated'} Successfully`)
        } else {
          this.showErrorMessage(`Cannot ${this.modalPurpose === 'add' ? 'Add' : 'Update'} Client`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    clearOnClick() {
      this.form = {}
    },
  },
}
</script>
<style scoped>

</style>
