<template>
  <div>
    <b-modal
      id="modal-client-set"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="md"
      title="Set Status"
      centered
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <ValidationObserver
          ref="createClientForm"
          v-slot="{ handleSubmit }"
          slim
        >
          <b-form
            class="my-8 py-1"
            @submit.prevent="handleSubmit(handleFormSubmit)"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <v-select
                    v-model="status"
                    :options="statusOptions"
                    label="name"
                    placeholder="Select Status"
                  >
                    <template
                      v-slot:option="option"
                      class="text-truncate"
                    >
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col
                class="w-100"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="w-100"
                  variant="primary"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ClientRepository = RepositoryFactory.get('client')

function initialState() {
  return {
    open: false,
    ids: null,
    loading: false,
    form: {},
    status: null,
    statusOptions: [
      'Active',
      'Inactive',
      'Staff Blocked',
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BOverlay,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(ids) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.ids = ids
    },
    async handleFormSubmit() {
      this.loading = true
      try {
        const payload = {
          ids: this.ids,
          status: this.status,
        }
        const res = await ClientRepository.updateClientStatus(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Status updated successfully')
          this.$parent.onClickRefresh()
          this.open = false
        } else {
          this.showErrorMessage('Failed to update status')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>

</style>
