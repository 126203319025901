<template>
  <div>
    <b-modal
      id="modal-seeder-run"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="ov-custom"
      :title="`Run Seeders for ${tenantName}`"
      centered
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-button
          class="w-100 my-1"
          style="pointer-events:none;"
          variant="success"
        >
          <h4 class="text-white mb-0">
            Seeder List
          </h4>
        </b-button>
        <b-row class="d-flex flex-wrap">
          <b-col
            v-for="(seeder, seeder_index) in rows"
            :key="seeder_index"
            cols="6"
            md="3"
          >
            <hr>
            <div class="d-flex justify-content-start align-items-center my-1">
              <b-form-checkbox
                v-model="selectedSeeders"
                :value="seeder.id"
                class="mr-1"
              />
              <span>{{ seeder.name }}</span>
            </div>
            <hr>
          </b-col>
        </b-row>
        <b-col
          class="d-flex justify-content-end align-items-center mb-1"
        >
          <div>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-1"
              type="submit"
              variant="success"
              @click="runDatabaseSeeder"
            >
              Run All Seeders
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              @click="runSelectedSeeders"
            >
              Run Selected Seeders
            </b-button>
          </div>
        </b-col>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BModal,
  BOverlay,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SeederRepository = RepositoryFactory.get('seeder')

function initialState() {
  return {
    open: false,
    id: null,
    tenantName: null,
    loading: false,
    rows: [],
    selectedSeeders: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BCol,
    BRow,
    BButton,
    BFormCheckbox,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id, tenantName) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.tenantName = tenantName
      await this.fetchSeederList()
    },
    async fetchSeederList() {
      try {
        this.loading = true
        const { data } = (await SeederRepository.getSeederList('filter[is_executable]=1')).data
        this.rows = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    // async runDatabaseSeeder() {
    //   try {
    //     this.loading = true
    //     const payload = {
    //       seeders: this.rows.map(seeder => seeder.id),
    //     }
    //     const res = await SeederRepository.runSelectedSeeders(this.id, payload)
    //     if (res.status === 200) {
    //       this.showSuccessMessage('Database seeded successfully')
    //       this.open = false
    //     } else {
    //       this.showErrorMessage('Couldn\'t run database seeder')
    //     }
    //   } catch (e) {
    //     this.convertAndNotifyError(e)
    //   }
    //   this.loading = false
    // },
    async runSelectedSeeders() {
      const selectedSeederNames = this.rows.filter(seeder => this.selectedSeeders.includes(seeder.id)).map(seeder => `<br><b>${seeder.name}</b><br>`)
      this.$swal({
        title: 'Are you sure?',
        html: `<div>You have chosen to run the following seeders <br> ${selectedSeederNames.toString().replaceAll(',', '')}<br></div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, run them!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.selectedSeederHandler()
        }
      })
    },
    async selectedSeederHandler() {
      try {
        this.loading = true
        const payload = {
          seeders: this.selectedSeeders,
        }
        const res = await SeederRepository.runSelectedSeeders(this.id, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Seeding completed successfully')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage('Couldn\'t run seeders')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>

</style>
